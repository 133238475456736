import React, { useContext, useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { doc, onSnapshot } from "firebase/firestore";

import { FirebaseContext } from "../../components/Firebase";
import Seo from "../../components/seo";
import { ContentBg, ContentBox } from "../../components/ui";
import LoginFirst from "../../components/loginfirst";
import downloadFile from "../../photos/wc2022/markspredictions.pdf";

const Home = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ul {
    li {
      margin-bottom: 0.5rem;
      text-align: right;
      list-style: none;
    }
  }
`;

const Img = styled.img`
  width: 100%;
  max-width: 400px;
  border: 1px solid gold;
  margin: auto;
`;

const NavList = styled.ul`
  flex: 1;
`;

const WorldCup2022 = () => {
  const { firebase, user, userLocale } = useContext(FirebaseContext);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    if (!firebase || !user) {
      navigate("/");
    } else {
      const unsubscribe = onSnapshot(
        doc(firebase.db, "publicProfiles", user.email),
        doc => {
          setProfile(doc.data());
        }
      );
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, []);

  return (
    <ContentBg src="/static/c41c656219d120e7127cf400171a9d84/4c91a/players.png">
      <ContentBox>
        <Seo title="World Cup Poule 2022" />

        {user ? (
          <>
            <Home>
              <h1>World Cup Poule 2022</h1>
              <NavList>
                <li>
                  <Link
                    to={
                      profile && profile.canUpdate
                        ? "worldcup2022/schedule-pre-wc"
                        : "worldcup2022/schedule"
                    }
                  >
                    <FormattedMessage id="wc2022_predictions" />
                  </Link>
                </li>
                <li>
                  <Link to="worldcup2022/standings">
                    <FormattedMessage id="wc2022_standings" />
                  </Link>
                </li>
                <li>
                  <Link to="worldcup2022/rules">
                    <FormattedMessage id="wc2022_rules" />
                  </Link>
                </li>
                <li>
                  <a
                    href={downloadFile}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Mark's <FormattedMessage id="wc2022_predictions" /> (PDF)
                  </a>
                </li>

                {user && user.userId === process.env.GATSBY_FIREBASE_ADMIN_UID && (
                  <li>
                    <Link to="worldcup2022/admin">Admin</Link>
                  </li>
                )}
              </NavList>
            </Home>
            {/* <Img src={worldcupgif} alt="worldcup" /> */}
            {userLocale.indexOf("nl") > -1 ? (
              <div>
                <h3>Thanks for playing!</h3>
                <p>
                  Allereerst, felicitaties aan alle winnaar van de verschillende
                  subleagues! Persoonlijk deed ik in de Groepsfase nog leuk mee,
                  maar daarna ging het erg snel bergafwaarts... 😅
                </p>
                <p>
                  Maar goed, blijft leuk altijd zo'n Poule en wat mij betreft
                  doen we het over zo'n anderhalf jaar gewoon nog een keertje
                  over voor Fußball-Europameisterschaft 2024. Tot dan!
                </p>
                <p>
                  -<a href="https://wa.me/14158280753">Mark</a>
                </p>
              </div>
            ) : (
              <div>
                <h3>Thanks for playing!</h3>
                <p>
                  First of all, congrats to the winners of the different
                  Sub-leagues! Personally I did fairly well during the Group
                  Stages, but after that it went downhill pretty quickly... 😅
                </p>
                <p>
                  Anyway, I've hosted these Poules a number of times through the
                  years and it's always just a lot of fun, both to participate
                  as well as for me to hack away a bit. :)
                </p>
                <p>
                  I'll probably do another one of these in about a year and
                  half, during the Fußball-Europameisterschaft 2024. So see you
                  then!
                </p>
                <p>
                  -<a href="https://wa.me/14158280753">Mark</a>
                </p>
              </div>
            )}
          </>
        ) : (
          <>
            {userLocale.indexOf("nl") > -1 ? (
              <>
                <h1>World Cup Poule 2022</h1>
                <p>
                  Hey hallo! Als je hier beland bent dan weet je waarschijnlijk
                  wel wat de bedoeling is.
                </p>
                <p>Een prettige WK gewenst!</p>
                <p>-Mark</p>
              </>
            ) : (
              <>
                <h1>World Cup Poule 2022</h1>
                <p>
                  Hello there! If you've landed here, you're <em>probably</em>{" "}
                  familiar with what a Poule is. However, THIS World Cup Poule
                  is aimed to allow <em>anyone</em> to win! Of course, there's
                  the predicting of scores, BUT to make it a little more
                  interesting / random, each match also has a question you need
                  to answer. These questions are impossible to predict, even for
                  the most seasoned{" "}
                  <span style={{ textDecoration: "line-through" }}>soccer</span>{" "}
                  football fan... in other words: even if you know nothing about{" "}
                  <span style={{ textDecoration: "line-through" }}>soccer</span>{" "}
                  football, anyone can win! (just ask the 2021 Euro Cup Pool
                  winner!)
                </p>
                <p>Go ahead and sign up or login below!</p>
                <p>-Mark</p>
              </>
            )}
            <LoginFirst />
          </>
        )}
      </ContentBox>
    </ContentBg>
  );
};

export default WorldCup2022;
