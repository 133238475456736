import React from "react";
import { navigate } from "gatsby";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { Button } from "./ui";

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  button {
    flex: 1;
  }
`;

const LoginFirst = () => {
  return (
    <>
      <p>
        <FormattedMessage id="login_this_is_restricted" />
      </p>
      <Buttons>
        <Button onClick={() => navigate("/register")}>
          <FormattedMessage id="register" />
        </Button>
        <Button onClick={() => navigate("/login")}>
          <FormattedMessage id="login" />
        </Button>
      </Buttons>
    </>
  );
};

export default LoginFirst;
